export const environment = {
  production: true,
  apiUrl: 'https://api.dev.ocean3d.in',
  homeUrl: 'https://dev.ocean3d.in',
  shopUrl: 'https://shop.dev.ocean3d.in',
  printUrl: 'https://print.dev.ocean3d.in',
  faqUrl: 'https://support.dev.ocean3d.in/faqs',
  supportUrl: 'https://support.dev.ocean3d.in',
  trackOrderUrl: 'https://account.dev.ocean3d.in/track-order',
  loginUrl: 'https://account.dev.ocean3d.in/login',
  registerUrl: 'https://account.dev.ocean3d.in/register',
  ordersUrl: 'https://account.dev.ocean3d.in/orders',
  accountUrl: 'https://account.dev.ocean3d.in/account',
  walletUrl: 'https://account.dev.ocean3d.in/wallet',
  addressUrl: 'https://account.dev.ocean3d.in/address',
};
