<header class="p-3 mb-3 border-bottom">
  <div class="container">
    <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
      <ul class="nav me-auto">
        <li class="nav-item"><a href="{{urls.home}}" target="_parent" class="nav-link link-dark px-2">Home</a></li>
        <li class="nav-item"><a href="{{urls.shop}}" target="_parent" class="nav-link link-dark px-2">Shop</a></li>
        <li class="nav-item"><a href="{{urls.print}}" target="_parent" class="nav-link link-dark px-2">Print</a></li>
        <li class="nav-item"><a href="{{urls.faq}}" target="_parent" class="nav-link link-dark px-2" [hidden]="hideDuplicate">FAQs</a></li>
        <li class="nav-item"><a href="{{urls.support}}" target="_parent" class="nav-link link-dark px-2">Support</a></li>

        <li class="nav-item"><a href="{{urls.orders}}" target="_parent" class="nav-link link-dark px-2" *ngIf="isLoggedIn">Orders</a></li>
        <li class="nav-item"><a href="{{urls.wallet}}" target="_parent" class="nav-link link-dark px-2" *ngIf="isLoggedIn">Wallet</a></li>
        <li class="nav-item"><a href="{{urls.address}}" target="_parent" class="nav-link link-dark px-2" [hidden]="hideDuplicate" *ngIf="isLoggedIn">Addresses</a></li>
        <li class="nav-item"><a href="{{urls.account}}" target="_parent" class="nav-link link-dark px-2" *ngIf="isLoggedIn">Account</a></li>
        <li class="nav-item"><a class="nav-link link-dark px-2 text-danger btn" (click)="logout()" *ngIf="isLoggedIn">Logout</a></li>
      </ul>
      <ul class="nav" *ngIf="!isLoggedIn">
        <li class="nav-item"><a href="{{urls.track}}?redirectTo={{getCurrentUrl()}}" target="_parent" class="nav-link link-dark px-2">Track Order</a></li>
        <li class="nav-item"><a href="{{urls.login}}?redirectTo={{getCurrentUrl()}}" target="_parent" class="nav-link link-dark px-2">Login</a></li>
        <li class="nav-item"><a href="{{urls.register}}?redirectTo={{getCurrentUrl()}}" target="_parent" class="nav-link link-dark px-2">Sign up</a></li>
      </ul>
      <ul class="nav" *ngIf="isLoggedIn" [hidden]="hideDuplicate">
        <li class="nav-item" >
          <a href="https://www.facebook.com/ocean3d.in" target="_blank" style="color: #000000; font-size: 30px;">
            <i class="bi bi-facebook"></i></a>
        </li>
        &nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;
        <li class="nav-item">
          <a href="https://www.instagram.com/ocean3d.in" target="_blank" style="color: #000000; font-size: 30px;">
            <i class="bi bi-instagram"></i>
          </a>
        </li>
        &nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;
          <li class="nav-item">
            <a href="https://www.youtube.com/@ocean3dindia" target="_blank"  style="color: #000000; font-size: 30px;">
              <i class="bi bi-youtube"></i>
            </a>
          </li>
      </ul>
    </div>
  </div>
</header>