import { Routes } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HeaderTestComponent } from './header-test/header-test.component';
import { StlViewerComponent } from './stl-viewer/stl-viewer.component';
import { HeaderV2Component } from './header-v2/header-v2.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderV3Component } from './header-v3/header-v3.component';

export const routes: Routes = [
  {
    path: 'header',
    component: HeaderComponent
  },
  {
    path: "test-header",
    component: HeaderTestComponent
  },
  {
    path: "header-v2",
    component: HeaderV2Component
  },
  {
    path: "header-v3",
    component: HeaderV3Component
  },
  {
    path: "stl-viewer",
    component: StlViewerComponent
  },
  {
    path: "footer",
    component: FooterComponent
  }
];
