import { Component, OnInit, importProvidersFrom } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import {
  HttpClientModule,
} from '@angular/common/http';
import { AccountService } from './services/account.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [
    RouterOutlet,
    NotAllowedComponent,
    CommonModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    AccountService
  ],
})
export class AppComponent implements OnInit {
  allowLoading = false;
  ngOnInit() {
    if (window.parent !== window || environment.production === false || window.location.pathname === '/stl-viewer') {
      this.allowLoading = true;
    } else {
      this.allowLoading = false;
    }
  }
  urls = {
    home: environment.homeUrl,
    shop: environment.shopUrl,
    print: environment.printUrl,
    faq: environment.faqUrl,
    support: environment.supportUrl,
    login: environment.loginUrl,
    register: environment.registerUrl,
  }
}
