import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StlModelViewerModule } from 'angular-stl-model-viewer';
import * as THREE from 'three';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-stl-viewer',
  standalone: true,
  imports: [
    StlModelViewerModule
  ],
  templateUrl: './stl-viewer.component.html',
  styleUrl: './stl-viewer.component.css'
})
export class StlViewerComponent implements OnInit {

  models: string[] = []

  constructor(private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      if (params['token']) {
        this.models.push(`${environment.apiUrl}/print/user-files/download?type=stl&fileToken=${params['token']}`)
      }
    });
  }

  camera = new THREE.PerspectiveCamera(75, 300 / 300, 0.1, 1000);
  renderer = new THREE.WebGLRenderer();
  material = new THREE.MeshPhongMaterial({ color: 0x1e8ef7 })

  ngOnInit(): void {
    this.initThree();
  }

  initThree(): void {
    // Create a camera
    this.camera = new THREE.PerspectiveCamera(75, 500 / 500, 0.1, 1000);
    this.camera.position.set(0, -4, 5); // Set the camera position
    this.camera.lookAt(0, 0, 0); // Look at the center of the scene

    // Create a renderer
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setSize(300, 300);
  }
 
}
