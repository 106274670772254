import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css',
})
export class HeaderComponent implements OnInit {
  isLoggedIn = true;
  urls = {
    home: environment.homeUrl,
    shop: environment.shopUrl,
    print: environment.printUrl,
    faq: environment.faqUrl,
    support: environment.supportUrl,
    login: environment.loginUrl,
    register: environment.registerUrl,
    orders: environment.ordersUrl,
    account: environment.accountUrl,
    wallet: environment.walletUrl,
    address: environment.addressUrl,
    track: environment.trackOrderUrl,
  };
  hideDuplicate = true;
  constructor(private accountService: AccountService) {}
  ngOnInit() {
    this.accountService.validateSession().subscribe(
      (response: any) => {
        this.isLoggedIn = !!response.Data.User;
        if (this.isLoggedIn) {
          sessionStorage.setItem('user', JSON.stringify(response.Data.User));
        }
      },
      (error: any) => {
        this.accountService.createSession().subscribe((response: any) => {
          this.isLoggedIn = false;
          sessionStorage.removeItem('user');
        });
      }
    );
    if(window.innerWidth > 768){
      this.hideDuplicate = false;
    }
    this.startChecking();
  }

  isTabActive(url: string) {
    return url.includes(window.parent.location.host);
  }

  checkLogin() {
    this.accountService.validateSession().subscribe(
      (response: any) => {
        this.isLoggedIn = !!response.Data.User;
        if (this.isLoggedIn) {
          sessionStorage.setItem('user', JSON.stringify(response.Data.User));
        }
      },
      (error: any) => {
        this.accountService.createSession().subscribe((response: any) => {
          this.isLoggedIn = false;
          sessionStorage.removeItem('user');
        });
      }
    );
  }

  getCurrentUrl() {
    return this.urls.shop;
    // return window.parent.location.href;
  }

  startChecking() {
    setInterval(() => {
      this.checkLogin();
    }, 15000);
  }

  logout() {
    this.accountService.logout().subscribe((response: any) => {
      setTimeout(() => {
        this.isLoggedIn = false;
        sessionStorage.removeItem('user');
        window.parent.location.href = environment.homeUrl;
      }, 1000);
    });
  }
}
