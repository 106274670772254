import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account.service';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-header-v3',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-v3.component.html',
  styleUrl: './header-v3.component.css'
})
export class HeaderV3Component implements OnInit {
  isLoggedIn = false;
  urls = {
    home: environment.homeUrl,
    shop: environment.shopUrl,
    print: environment.printUrl,
    faq: environment.faqUrl,
    support: environment.supportUrl,
    login: environment.loginUrl,
    register: environment.registerUrl,
    orders: environment.ordersUrl,
    account: environment.accountUrl,
    wallet: environment.walletUrl,
    address: environment.addressUrl,
    track: environment.trackOrderUrl,
  };
  isMobile = true;
  constructor(private accountService: AccountService) {}
  ngOnInit() {
    this.accountService.validateSession().subscribe(
      (response: any) => {
        this.isLoggedIn = response.Data.User;
        if (this.isLoggedIn) {
          sessionStorage.setItem('user', JSON.stringify(response.Data.User));
        }
      },
      (error: any) => {
        this.accountService.createSession().subscribe((response: any) => {
          this.isLoggedIn = false;
          sessionStorage.removeItem('user');
        });
      }
    );
    if(window.innerWidth > 425){
      this.isMobile = false;
    }
    this.startChecking();
  }

  checkLogin() {
    this.accountService.validateSession().subscribe(
      (response: any) => {
        this.isLoggedIn = !!response.Data.User;
        if (this.isLoggedIn) {
          sessionStorage.setItem('user', JSON.stringify(response.Data.User));
        }
      },
      (error: any) => {
        this.accountService.createSession().subscribe((response: any) => {
          this.isLoggedIn = false;
          sessionStorage.removeItem('user');
        });
      }
    );
  }

  startChecking() {
    setInterval(() => {
      this.checkLogin();
    }, 15000);
  }

  logout() {
    this.accountService.logout().subscribe((response: any) => {
      setTimeout(() => {
        this.isLoggedIn = false;
        sessionStorage.removeItem('user');
        window.parent.location.href = environment.homeUrl;
      }, 1000);
    });
  }

}
