import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-header-test',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './header-test.component.html',
  styleUrl: './header-test.component.css',
})
export class HeaderTestComponent {
  isMobileMenuOpen = false;
  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }
}
