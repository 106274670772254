import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  public createSession() {
    return this.http.post(`${this.baseUrl}/account/users/create-session`, {}, { withCredentials: true });
  }

  public login(credential: { Email: string; Password: string;}) {
    return this.http.post(`${this.baseUrl}/account/users/login`, credential, { withCredentials: true });
  }

  public validateSession() {
    return this.http.get(`${this.baseUrl}/account/users/validate-session`, { withCredentials: true });
  }

  public logout() {
    return this.http.post(`${this.baseUrl}/account/users/logout`, {}, { withCredentials: true });
  }
}
