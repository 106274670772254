<header class="header">
    <div class="logo">YourLogo</div>
    <ul class="navigation">
        <li><a href="#">Home</a></li>
        <li><a href="#">Shop</a></li>
        <li><a href="#">Print</a></li>
        <li><a href="#">FAQs</a></li>
        <li><a href="#">Support</a></li>
    </ul>
    <div class="login">Login/Signup</div>
    <button class="mobile-menu-button" (click)="toggleMobileMenu()">☰</button>
    <ul class="mobile-menu-list" [ngClass]="{'show': isMobileMenuOpen}">
        <li><a href="#">Home</a></li>
        <li><a href="#">Shop</a></li>
        <li><a href="#">Print</a></li>
        <li><a href="#">FAQs</a></li>
        <li><a href="#">Support</a></li>
    </ul>
</header>
