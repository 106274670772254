<div class="pg-footer">
  <footer class="footer">
    <svg class="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
      <path class="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
    </svg>
    <div class="footer-content">
      <div class="footer-content-column">
        <div class="footer-logo">
          <a class="footer-logo-link" href="https://ocean3d.in/" target="_parent">
            <span class="hidden-link-text">LOGO</span>
            <img src="https://cdn.ocean3d.in/global/logo-white.png" alt="OCEAN 3D" width="80%">
          </a>
        </div>
        <div class="footer-menu">
          <h2 class="footer-menu-name"> Get Started</h2>
          <ul id="menu-get-started" class="footer-menu-list">
            <li class="menu-item menu-item-type-post_type menu-item-object-product">
              <a href="https://shop.ocean3d.in/categories/all-categories" target="_parent">Shop 3D Printed Items</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-product">
              <a href="https://print.ocean3d.in/" target="_parent">Print Your Own Design</a>
            </li>
            <li class="menu-item menu-item-type-post_type menu-item-object-product">
              <a href="https://ocean3d.shiprocket.co/tracking" target="_parent">Track Guest Order</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-content-column">
        <div class="footer-menu">
          <h2 class="footer-menu-name"> Legal</h2>
          <ul id="menu-legal" class="footer-menu-list">
            <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://ocean3d.in/privacy-policy" target="_blank">Privacy Policy</a></li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://ocean3d.in/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://ocean3d.in/payment-policy" target="_blank">Payment Policy</a></li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://ocean3d.in/shipping-policy" target="_blank">Shipping & Delivery</a></li>
            <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://ocean3d.in/cancellation-and-refund-policy" target="_blank">Cancellation & Refund</a></li>
          </ul>
        </div>
      </div>
      <div class="footer-content-column">
        <div class="footer-menu">
          <h2 class="footer-menu-name"> Quick Links</h2>
          <ul id="menu-quick-links" class="footer-menu-list">
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_parent" rel="noopener noreferrer" href="https://support.ocean3d.in/faqs/all?Topic=All%20Topics&SubTopic=All%20Sub%20Topics&Type=FAQs">FAQ's</a>
            </li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_parent" rel="noopener noreferrer" href="https://account.ocean3d.in/dashboard">{{ isLoggedIn ? "Account" : "Login" }}</a>
            </li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_parent" rel="noopener noreferrer" href="https://account.ocean3d.in/addresses">Saved Addresses</a>
            </li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_parent" rel="noopener noreferrer" href="https://account.ocean3d.in/coupons">View Offers</a>
            </li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_parent" rel="noopener noreferrer" href="https://account.ocean3d.in/orders/shop">Order History</a>
            </li>
            <li class="menu-item menu-item-type-custom menu-item-object-custom">
              <a target="_parent" rel="noopener noreferrer" href="https://account.ocean3d.in/notification-preferences">Notification Preference</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-content-column">
        <div class="footer-call-to-action">
          <h2 class="footer-call-to-action-title">Customer Care</h2>
          <p class="footer-call-to-action-description"> Need Help?</p>
          <a class="footer-call-to-action-button button" href="https://support.ocean3d.in" target="_blank"> Get in Touch </a>
        </div>
        <div class="footer-call-to-action">
          <h2 class="footer-call-to-action-title">Got Question? Call us</h2>
          <p class="footer-call-to-action-link-wrapper"> <a class="footer-call-to-action-link" href="tel:+917013099879" target="_parent"> +91 70130 99879 </a></p>
        </div>
      </div>
      <div class="footer-social-links"> <svg class="footer-social-amoeba-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236 54">
          <path class="footer-social-amoeba-path" d="M223.06,43.32c-.77-7.2,1.87-28.47-20-32.53C187.78,8,180.41,18,178.32,20.7s-5.63,10.1-4.07,16.7-.13,15.23-4.06,15.91-8.75-2.9-6.89-7S167.41,36,167.15,33a18.93,18.93,0,0,0-2.64-8.53c-3.44-5.5-8-11.19-19.12-11.19a21.64,21.64,0,0,0-18.31,9.18c-2.08,2.7-5.66,9.6-4.07,16.69s.64,14.32-6.11,13.9S108.35,46.5,112,36.54s-1.89-21.24-4-23.94S96.34,0,85.23,0,57.46,8.84,56.49,24.56s6.92,20.79,7,24.59c.07,2.75-6.43,4.16-12.92,2.38s-4-10.75-3.46-12.38c1.85-6.6-2-14-4.08-16.69a21.62,21.62,0,0,0-18.3-9.18C13.62,13.28,9.06,19,5.62,24.47A18.81,18.81,0,0,0,3,33a21.85,21.85,0,0,0,1.58,9.08,16.58,16.58,0,0,1,1.06,5A6.75,6.75,0,0,1,0,54H236C235.47,54,223.83,50.52,223.06,43.32Z"></path>
        </svg>
        <a class="footer-social-link facebook-n" href="https://www.facebook.com/ocean3d.in" target="_blank">
          <span class="hidden-link-text">Facebook</span>
          <img src="https://cdn.ocean3d.in/global/facebook.png" alt="Facebook" width="400%">
        </a>
        <a class="footer-social-link youtube-n" href="https://www.youtube.com/@ocean3dindia" target="_blank">
          <span class="hidden-link-text">Youtube</span>
          <img src="https://cdn.ocean3d.in/global/youtube.png" alt="YouTube" width="340%">
        </a>
        <a class="footer-social-link instagram-n" href="https://www.instagram.com/ocean3d.in" target="_blank">
          <span class="hidden-link-text">Instagram</span>
          <img src="https://cdn.ocean3d.in/global/instagram.png" alt="Instagram" width="600%">
        </a>
        <a class="footer-social-link x-n" href="https://x.com/ocean3dindia" target="_blank" style="padding-left: 10px;">
          <span class="hidden-link-text">X aka Twitter</span>
          <img src="https://cdn.ocean3d.in/global/x.png" alt="X aka Twitter" width="250%">
        </a>
      </div>
    </div>
    <div class="footer-copyright">
      <div class="footer-copyright-wrapper">
        <p class="footer-copyright-text">
          <a class="footer-copyright-link"> Copyright &copy; 2024 KROILA 3D PVT LTD. All rights reserved. </a>
        </p>
      </div>
    </div>
  </footer>
</div>