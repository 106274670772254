import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-allowed',
  standalone: true,
  imports: [],
  templateUrl: './not-allowed.component.html',
  styleUrl: './not-allowed.component.css'
})
export class NotAllowedComponent implements OnInit {

  ngOnInit(): void {
    const root = document.documentElement;
    let eyef = document.getElementById('eyef');
    let cx = document.getElementById("eyef")?.getAttribute("cx");
    let cy = document.getElementById("eyef")?.getAttribute("cy");
    document.addEventListener("mousemove", evt => {
      let x = evt.clientX / innerWidth;
      let y = evt.clientY / innerHeight;
      root.style.setProperty("--mouse-x", String(x));
      root.style.setProperty("--mouse-y", String(y));
      cx = String(115 + 30 * x);
      cy = String(50 + 30 * y);
      eyef?.setAttribute("cx", cx);
      eyef?.setAttribute("cy", cy);
    });
    document.addEventListener("touchmove", touchHandler => {
      let x = touchHandler.touches[0].clientX / innerWidth;
      let y = touchHandler.touches[0].clientY / innerHeight;
      root.style.setProperty("--mouse-x", String(x));
      root.style.setProperty("--mouse-y", String(y));
    });
  }

}
