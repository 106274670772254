<nav class="py-2 border-bottom" *ngIf="!isMobile">
  <div class="container d-flex flex-wrap">
    <ul class="nav me-auto">
      <li class="nav-item"><a href="https://api.whatsapp.com/send?phone=917013099879" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-whatsapp"></i>  &nbsp;+91 70130 99879</a></li>
      <li class="nav-item nav-link link-body-emphasis px-2">|</li>
      <li class="nav-item"><a href="https://www.facebook.com/ocean3d.in" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-facebook"></i></a></li>
      <li class="nav-item"><a href="https://www.instagram.com/ocean3d.in" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-instagram"></i></a></li>
      <li class="nav-item"><a href="https://www.youtube.com/@ocean3dindia" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-youtube"></i></a></li>
    </ul>
    <ul class="nav" *ngIf="!isLoggedIn">
      <li class="nav-item"><a href="{{urls.login}}" target="_parent" class="nav-link link-body-emphasis px-2">Login</a></li>
      <li class="nav-item"><a href="{{urls.register}}" target="_parent" class="nav-link link-body-emphasis px-2">Sign up</a></li>
    </ul>
    <ul class="nav" *ngIf="isLoggedIn">
      <li class="nav-item"><a href="{{urls.account}}" target="_parent" class="nav-link link-body-emphasis px-2">My Account</a></li>
      <li class="nav-item"><a href="{{urls.orders}}" target="_parent" class="nav-link link-body-emphasis px-2">Orders</a></li>
      <li class="nav-item"><a class="nav-link link-body-emphasis px-2 btn text-danger" (click)="logout()">Logout</a></li>
    </ul>
  </div>
</nav>

<nav class="py-2 bg-body-tertiary border-bottom" *ngIf="isMobile">
  <div class="container d-flex flex-wrap">
    <ul class="nav me-auto">
      <li class="nav-item"><a href="https://api.whatsapp.com/send?phone=917013099879" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-whatsapp"></i> &nbsp;+91 70130 99879</a></li>
      <li class="nav-item nav-link link-body-emphasis px-2" *ngIf="isLoggedIn">|</li>
      <li class="nav-item" *ngIf="isLoggedIn"><a href="https://www.facebook.com/ocean3d.in" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-facebook"></i></a></li>
      <li class="nav-item" *ngIf="isLoggedIn"><a href="https://www.instagram.com/ocean3d.in" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-instagram"></i></a></li>
      <li class="nav-item" *ngIf="isLoggedIn"><a href="https://www.youtube.com/@ocean3dindia" target="_blank" class="nav-link link-body-emphasis px-2"><i class="bi bi-youtube"></i></a></li>
    </ul>
    <ul class="nav" *ngIf="!isLoggedIn">
      <li class="nav-item"><a href="{{urls.login}}" target="_parent" class="nav-link link-body-emphasis px-2">Login</a></li>
      <li class="nav-item"><a href="{{urls.register}}" target="_parent" class="nav-link link-body-emphasis px-2">Sign up</a></li>
    </ul>
    <div class="flex-shrink-0 dropdown" *ngIf="isLoggedIn">
      <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://cdn.ocean3d.in/global/profile.png" alt="mdo" width="32" height="32" class="rounded-circle">
      </a>
      <ul class="dropdown-menu text-small shadow">
        <li><a class="dropdown-item" href="{{urls.account}}" target="_parent">My Account</a></li>
        <li><a class="dropdown-item" href="{{urls.orders}}" target="_parent">Orders</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item text-danger" (click)="logout()">Sign out</a></li>
      </ul>
    </div>
  </div>
</nav>